body {
  margin: 0 !important;
}

/* width */
div::-webkit-scrollbar {
  width: 10px;
  display: none;
}

/* Track */
div::-webkit-scrollbar-track {
  background: white;
  /* visibility: hidden; */
}

/* Handle */
div::-webkit-scrollbar-thumb {
  background: goldenrod;
  /* visibility: hidden; */
}

/* Handle on hover */
div::-webkit-scrollbar-thumb:hover {
  background: goldenrod;
  /* visibility: hidden; */
}

* {
  box-sizing: border-box;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

[class*="col-"] {
  float: left;
}

/* For mobile phones: */
[class*="col-"] {
  width: 100%;
}

[class*="col-3"] {
  width: 25%;
}

/* [class*="rightPanel"] {
  overflow-x: auto;
  white-space: nowrap;
} */

/* [class*="innerClass"] {
  border: 3px solid #eae9df;
  display: inline-flex;
} */

/* [class*="tryonCard"] {
  border-left: none;
} */

/* .rightPanel {
  overflow-x: auto;
  white-space: nowrap;
}

#cardID {
  border: 3px solid #eae9df;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  height: 25vw;
  background-color: red;
  border: 1px solid black;
  max-height: 100vh;
} */

@media only screen and (min-width: 1025px) {
  .col-2 {
    width: 25%;
  }
  .col-5 {
    width: 50%;
  }
  .col-3 {
    width: 25%;
  }
  .col-10 {
    width: 50%;
  }

  .mobile_version {
    display: none;
  }

  #powered {
    background-color: white;
    bottom: -6%;
    width: 100%;
    z-index: 99;
    position: absolute;
    font-size: 12px;
  }

  #productSection {
    height: 76%;
    overflow-y: scroll;
    /* position: absolute; */
  }

  /* .tryonCard {
    border-left: 5px solid goldenrod;
  } */

  #productCard {
    width: 50%;
    border: 3px solid #eae9df;
  }

  #right-main {
    /* margin-top: 0px; */
    /* z-index: 999999999; */
    /* position: absolute; */
    /* right: 0px; */
    /* height: 83vh; */
  }
  /* .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;} */
}

@media only screen and (max-width: 1024px) {
  #productSection {
    overflow-x: auto;
    white-space: nowrap;
  }

  #footer_button {
    display: none !important;
  }

  #vrslogo-desktop {
    display: none;
  }

  #powered {
    display: none;
  }

  #loader-screen {
    height: 70% !important;
  }

  .mobile_version {
    display: visible;
  }
  .innerClass {
    width: 33.33%;
    display: inline-flex;
    /* border: 3px solid #eae9df; */
  }

  #right-main {
    display: none;
  }

  #tryonParent {
    height: 100vh !important;
  }

  #powered {
    background-color: white;
    top: -3%;
    width: 170px;
    position: absolute;
    z-index: 9;
  }

  #categoryText {
    display: none;
  }

  #tryonCard {
    border-left: none !important;
    /* bottom: 0; */
    position: absolute;
    background: white;
  }

  #footer_button {
    bottom: 12% !important;
  }

  #business-image {
    top: 0;
    right: 2%;
    padding: 1%;
    position: fixed;
  }
}

.card {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  height: 16%;
  margin: 1rem;
  position: relative;
  width: 80px;
  cursor: pointer;
}

.card-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(255, 230, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card-1:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(255, 230, 0, 0.315);
}

.cardSelected {
  border-radius: 2rem !important;
}

.card-mobile {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(255, 230, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

p {
  font-family: "-webkit-pictograph";
}

#tryonParent canvas {
  width: 100% !important;
  height: inherit !important;
}

#footer_button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: auto;
  width: 100%;
}

.messageAnimation::after {
  content: "";
  display: block;
  position: absolute;
  left: -110%;
  top: 130%;
  height: 40px;
  font-size: 14px;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  animation: txt 5s infinite;
  width: 350%;
  text-align: center;
}

.messageAnimation {
  display: block;
  width: 70.3%;
  height: 100%;
  bottom: 0;
  /* box-shadow: inset 0 0 10px #000000; */
  color: black;
  z-index: 9996;
}

@keyframes txt {
  0% {
    content: "We are crafting beautiful Virtual Try On Experience.. Please wait";
  }
  100% {
    content: "Grace your beauty with Virtual Try On";
  }
}

@keyframes txt {
  0% {
    content: "We are crafting beautiful Virtual Try On Experience.. Please wait";
  }
  100% {
    content: "Grace your beauty with Virtual Try On";
  }
}

/* Mobile Version */
body {
  margin: 0 auto;
  color: #373737;
  background: #f1f1f1;
  font-family: sans-serif;
}

.clear-icon {
  z-index: 999;
  margin-left: 1%;
  margin-bottom: 3%;
}

.capture-icon {
  z-index: 999;
  margin-right: 1%;
  /* margin-bottom: -8%; */
  text-align: right;
}

.zout-icon {
  z-index: 999;
  margin-right: 1%;
  margin-bottom: 3%;
  text-align: right;
}

.zin-icon {
  z-index: 999;
  margin-right: 1%;
  margin-bottom: 3%;
  text-align: right;
}

.vrs-logo {
  z-index: 999;
  top: 0.5%;
  position: fixed;
  right: 1%;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.clearfix:after,
.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
  display: block;
}

input[name="menu"] {
  display: none;
}

.menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  /* background-color: white; */
}

.menu_sub {
  position: fixed;
  bottom: 60px;
  width: 100%;
}

.menu_color {
  position: fixed;
  bottom: 110px;
  width: 100%;
}

.list_sub {
  width: 100%;
  height: 120px;
  overflow: hidden;
  background: lightpink;
  position: relative;
}

.list_sub .link-wrap_sub {
  width: 100%;
  height: 100%;
  display: table;
}

.list_sub .link-wrap_sub > label {
  color: rgba(0, 0, 0, 0.75);
  z-index: 999;
  min-width: 68px;
  max-width: 168px;
  width: 33%;
  font-size: 30px;
  cursor: pointer;
  padding: 4px 12px;
  text-align: center;
  position: relative;
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list_sub .link-wrap_sub > label > i,
.list_sub .link-wrap_sub > label > span {
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

/* .material-icons {
  font-size: 60px !important;
} */

.list .link-wrap > label > span {
  height: 0;
  display: block;
  font-weight: 500;
  font-size: 25px;
  -webkit-transform: translateY(45px);
  transform: translateY(45px);
}

.list {
  width: 100%;
  height: 60px;
  overflow: hidden;
  background: deeppink;
  position: relative;
}

.list .link-wrap {
  width: 100%;
  height: 100%;
  display: table;
}

.list .link-wrap > label {
  color: rgba(255, 255, 255, 0.75);
  z-index: 999;
  min-width: 68px;
  max-width: 168px;
  width: 20%;
  font-size: 12px;
  cursor: pointer;
  padding: 4px 12px;
  text-align: center;
  position: relative;
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list .link-wrap > label > i,
.list .link-wrap > label > span {
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

/* .material-icons {
  font-size: 60px !important;
} */

.list .link-wrap > label > span {
  height: 0;
  display: block;
  font-weight: 500;
  font-size: 15px;
  -webkit-transform: translateY(45px);
  transform: translateY(45px);
}

#one:checked ~ .list label[for="one"] > span,
#two:checked ~ .list label[for="two"] > span,
#three:checked ~ .list label[for="three"] > span,
#four:checked ~ .list label[for="four"] > span,
#five:checked ~ .list label[for="five"] > span {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  height: auto;
}

#one:checked ~ .list .link-wrap > label[for="one"],
#two:checked ~ .list .link-wrap > label[for="two"],
#three:checked ~ .list .link-wrap > label[for="three"],
#four:checked ~ .list .link-wrap > label[for="four"],
#five:checked ~ .list .link-wrap > label[for="five"] {
  color: #fff;
}

div.scrollmenu {
  background-color: #333;
  overflow: auto;
  white-space: nowrap;
  overflow-y: hidden;
}

div.colormenu {
  /* background-color: white; */
  overflow: auto;
  white-space: nowrap;
  overflow-y: hidden;
  padding: 1%;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 4%;
  font-size: 15px;
  text-decoration: none;
  /* border-left: 3px solid white; */
}
